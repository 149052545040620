/**
 * レイアウト変更のしきい値
 */
export const BREAK_POINT = 767 as const;

/**
 * 言語
 */
export const LOCALE = document.documentElement.lang || ('ja' as const);

/**
 * Cookies
 */
export const COOKIE = {
  TERMS: 'chocure_terms_agreed',
  SECOND_TIME: 'chocure_second_access',
} as const;

/**
 * CSS Easings
 */
export const EASING = {
  easeInSine: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
  easeOutSine: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
  easeInOutSine: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
} as const;

/**
 * エンドポイント
 */
export const ENDPOINT = {
  COMMENT: '/api/article/%ARTICLE_ID/comments',
} as const;
