import PubSub from 'vanilla-pubsub';
import project from '@/js/project';

export default class DisableScroll {
  constructor() {
    this.state = {
      lastOffsetTop: 0,
    };
    this.scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    this.bind();
  }

  disable() {
    this.state.lastOffsetTop = project.state.scrollY;

    document.body.style.paddingRight = `${this.scrollBarWidth}px`;
    document.body.style.overflow = 'hidden';
    // below for Mobile Safari
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.top = `${this.state.lastOffsetTop}px`;
  }

  release() {
    document.body.style.top = '';
    document.body.style.position = '';
    document.body.style.width = '';
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
    window.scrollTo(0, this.state.lastOffsetTop);

    this.state.lastOffsetTop = 0;
  }

  bind() {
    this.disable = this.disable.bind(this);
    this.release = this.release.bind(this);

    PubSub.subscribe('disableScroll.disable', this.disable);
    PubSub.subscribe('disableScroll.release', this.release);
  }
}
