import WebFont from 'webfontloader';

export default class WebfontLoader {
  constructor() {
    this.options = {
      custom: {
        urls: [
          // Prata - Regular:400
          'https://fonts.googleapis.com/css2?family=Prata&display=swap',
          // Noto Sans JP - Regular:400, Bold:700
          'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap',
          // Noto Serif JP - Light:300, Regular:400, Medium:500
          'https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;400&display=swap',
          // Yakuhan JP - Mincho
          'https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanmp.min.css',
        ],
      },
      // loading: function() {},
      // active: function() {},
      // inactive: function() {},
      // fontloading: function(familyName, fvd) {},
      // fontactive: function(familyName, fvd) {},
      // fontinactive: function(familyName, fvd) {}
    };

    this.setupWebFont();
  }

  setupWebFont() {
    WebFont.load(this.options);
  }
}
