/* eslint-disable @typescript-eslint/ban-ts-comment */
import Renderer from '@/js/plugins/react-renderer';

import { Intro } from './Intro';

export default class {
  el: HTMLElement | null = null;

  constructor(selector: string) {
    this.el = document.querySelector(selector);

    if (!this.el) {
      return;
    }
    this.setup();
  }

  async setup() {
    const RendererInstance = new Renderer();
    const render = RendererInstance.getRenderer(Intro);

    if (this.el) {
      render({}, this.el);
    }
  }
}
