// for HMR
import '@/styles/app.scss';

// Libs
import PubSub from 'vanilla-pubsub';

// Utils
import { queryToObject } from '@/js/utils/parser';
import { getBrowser, getParsedUA, setCookie, getCookie } from './utils/browser';
import { NODE_ENV } from './utils/env';

// Plugins
import WebfontLoader from './plugins/webFontLoader';

// Modules
import Viewport from './modules/Viewport';
import HashPosition from './modules/HashPosition';
import DisableScroll from './modules/DisableScroll';
import SmoothScroll from './modules/SmoothScroll';
import SmoothScrollURLAdapter from './modules/SmoothScrollURLAdapter';
import Header from './modules/Header';
import Menu from './modules/Menu';
import Pagetop from './modules/Pagetop';
import CookieDialog from './modules/CookieDialog';
import Intro from '@/js/components/Intro';

// ProjectClass (singleton)
import project from './project';

import { COOKIE } from '@/js/constants';

// // Mocks
// import { initMocks } from '@/js/mocks/server';

class App {
  constructor() {
    this.isDev = NODE_ENV !== 'production';
    this._initialize();
    this.missingRootCount = 0;

    // if (this.isDev) {
    //   initMocks();

    //   if (!/safari/i.test(results?.browser?.name || '')) {
    //     import('lit-issue-reporter').then(({ createReporter }) => {
    //       createReporter({
    //         // eslint-disable-next-line no-undef
    //         token: process.env.GITHUB_TOKEN,
    //         owner: 'cinra',
    //         repository: 'chocure',
    //       });
    //     });
    //   }
    // }

    // for dynamic import
    this.pages = {
      top: 'top.js',
      archive: 'archive.js',
      post: 'post.js',
      contact: 'contact.js',
      admin: 'admin.js',
    };

    this._setup();
  }

  _initialize() {
    // Init webfont
    new WebfontLoader();

    // Define Browser UA
    const browser = getBrowser();
    const results = getParsedUA();
    document.documentElement.classList.add(browser);

    if (/safari/i.test(results?.browser?.name || '') && /^14\.0/.test(results?.browser?.version || '')) {
      document.documentElement.classList.add('legacy');
      document.documentElement.classList.add('flex-gap-not-supported');
    }
  }

  async _setup() {
    const query = queryToObject(location.search);
    const isSecondTime = getCookie(COOKIE.SECOND_TIME);

    // 現在ページを取得
    const $root = document.querySelector('.js-root');

    if (!$root && this.missingRootCount < 1) {
      this.missingRootCount++;
      window.addEventListener('DOMContentLoaded', this._setup);
      return;
    }

    if (!$root) {
      console.warn('.js-root[data-page="<chunkName>"] が未指定です');
      return;
    }

    const current = $root.dataset.page || '';

    // ページ共通で実行されるjs群
    this.globals = {
      viewport: new Viewport(),
      hashPosition: new HashPosition(),
      disableScroll: new DisableScroll(),
      smoothScroll: new SmoothScroll(),
      smoothScrollURLAdapter: new SmoothScrollURLAdapter('.js-toc'),
      header: new Header('.js-header'),
      menu: new Menu('.js-menu'),
      pagetop: new Pagetop(),
      cookieDialog: new CookieDialog('.js-cookieDialog'),
    };

    // イントロアニメーション判定
    if (!isSecondTime || (this.isDev && query.intro === '1')) {
      if (current === 'top') {
        project.addModule('intro', new Intro('.js-intro'));
      }
    } else {
      document.documentElement.classList.remove('is-waiting-intro');
    }

    // アクセス済を記録
    setCookie({
      name: COOKIE.SECOND_TIME,
      expire_hour: 24 * 365,
    });

    // ページに対応するDynamic Importモジュールを確認
    const page = this.pages[current] || false;

    // グローバルモジュールをprojectインスタンスに追加
    project.addModules(this.globals);

    // 現在ページ用のmodule郡をDynamic Importしてインスタンス化
    if (page) {
      this.currentPage = await this.importer(current);
    }

    PubSub.publish('App.ready');
    document.body.classList.add('is-app-ready');
  }

  importer(filename) {
    return (
      import(`./pages/${filename}`)
        /* eslint-disable-next-line new-cap */
        .then((Module) => new Module.default('.js-root'))
        .catch((err) => {
          console.error(err);
        })
    );
  }
}

new App();
