import PubSub from 'vanilla-pubsub';

export default class Header {
  constructor(selector) {
    this.el = document.querySelector(selector);
    this.state = {
      preScrollY: 0,
      scrollDir: '', // 'down' | 'up'
    };

    if (this.el) {
      this._initialize();
    }
  }

  _initialize() {
    const mainContent = document.querySelector('.l-main');
    this.mainOffset = mainContent.offsetHeight + mainContent.offsetTop;

    PubSub.subscribe('App.scroll', this.scroll.bind(this));
  }

  scroll({ scrollY, height }) {
    this.state.scrollDir = this.state.preScrollY > scrollY ? 'up' : 'down';

    if (scrollY > height) {
      document.body.classList.add('is-atf-scrolled');
    } else {
      document.body.classList.remove('is-atf-scrolled');
    }

    if (scrollY > this.mainOffset - height) {
      document.body.classList.add('is-main-scrolled');
    } else {
      document.body.classList.remove('is-main-scrolled');
    }

    if (scrollY > 100) {
      document.body.classList.add('is-scrolled');
      document.body.setAttribute('data-scroll-dir', this.state.scrollDir);
    } else {
      document.body.classList.remove('is-scrolled');
      document.body.removeAttribute('data-scroll-dir');
    }
    this.state.preScrollY = scrollY;
  }
}
