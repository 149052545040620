import { getCookie, setCookie } from '@/js/utils/browser';
import { COOKIE } from '@/js/constants';

export default class CookieDialog {
  constructor(selector) {
    this.selector = selector;
    this.el = document.querySelector(selector);
    this.cookie = getCookie(COOKIE.TERMS);

    if (this.cookie) {
      this.close();
    } else if (this.el) {
      this._initialize();
    }
  }

  _initialize() {
    this._bind();
    this.el.classList.add('-active');
  }

  _bind() {
    const els = {};
    els.close = document.querySelector(this.selector + '.-close');
    els.agree = document.querySelector(this.selector + '.-agree');

    if (els.close) {
      els.close.addEventListener('click', this.close.bind(this));
    }
    if (els.agree) {
      els.agree.addEventListener('click', this.agree.bind(this));
    }
  }

  close() {
    this.el.remove();
  }

  agree() {
    setCookie({
      name: COOKIE.TERMS,
      expire_hour: 24 * 365,
    });
    this.close();
  }
}
