import PubSub from 'vanilla-pubsub';
import { throttle, debounce } from '@/js/utils/event';
import { isMobile, getBrowser } from '@/js/utils/browser';

class Project {
  constructor() {
    this.$modules = {};
    this.$window = {
      isMobile: isMobile(),
      browser: getBrowser(),
      width: window.innerWidth,
      height: window.innerHeight,
      scrollX: window.pageXOffset,
      scrollY: window.pageYOffset,
      scrollBarWidth: window.innerWidth - document.body.clientWidth,
    };
    this._bind();
  }

  get state() {
    return this.$window;
  }

  get modules() {
    return this.$modules;
  }

  _bind() {
    this._onScroll = this._onScroll.bind(this);
    this._onResize = debounce(this._onResize.bind(this), 500);
    this._scrollDisable = this._scrollDisable.bind(this);
    this._scrollRelease = this._scrollRelease.bind(this);

    window.addEventListener('scroll', this._onScroll, { passive: true });
    window.addEventListener('resize', this._onResize, { passive: true });
    window.addEventListener('load', this._onLoad);

    PubSub.subscribe('App.methods.scroll.disable', this._scrollDisable);
    PubSub.subscribe('App.methods.scroll.release', this._scrollRelease);
  }

  _onScroll() {
    this.$window.scrollX = window.pageXOffset;
    this.$window.scrollY = window.pageYOffset;
    PubSub.publish('App.scroll.sensitive', this.state);

    throttle(() => {
      PubSub.publish('App.scroll', this.state);
    }, 200)();
  }

  _onResize() {
    const oldState = this.$window.isMobile;
    this.$window.width = window.innerWidth;
    this.$window.height = window.innerHeight;
    this.$window.scrollBarWidth = window.innerWidth - document.body.clientWidth;
    this.$window.isMobile = isMobile();

    PubSub.publish('App.resize', this.state);

    if (oldState !== this.$window.isMobile) {
      PubSub.publish('App.changeViewport', this.state);
    }
  }

  _scrollDisable() {
    document.body.style.paddingRight = `${this.$window.scrollBarWidth}px`;
    document.documentElement.classList.add('is-disable-scroll');
  }

  _scrollRelease() {
    document.documentElement.classList.remove('is-disable-scroll');
    document.body.style.paddingRight = '';
  }

  _onLoad() {
    PubSub.publish('App.load', this.state);
  }

  // インスタンス化済のモジュールを追加
  addModules(modules = {}) {
    if (!(modules instanceof Object)) {
      console.error("Error: 'modules' must be Object");
      return;
    }

    Object.keys(modules).forEach((name) => {
      this.addModule(name, modules[name]);
    });
  }

  addModule(name, instance) {
    this.$modules[name] = instance;
  }
}

export default new Project();
