import UAParser from 'ua-parser-js';
import { BREAK_POINT } from '@/js/constants';

const _userAgent = navigator.userAgent.toLowerCase();

/**
 * getParsedUA
 */
export function getParsedUA() {
  return new UAParser(_userAgent).getResult();
}

/**
 * isMobile
 */
export function isMobile() {
  // return window.innerWidth <= BREAK_POINT
  return window.matchMedia(`(max-width: ${BREAK_POINT}px)`).matches;
}

/**
 * getBrowser
 */
export function getBrowser() {
  if (_userAgent.indexOf('msie') != -1 || _userAgent.indexOf('trident') != -1) {
    return 'ie';
  } else if (_userAgent.indexOf('edge') != -1) {
    return 'edge';
  } else if (_userAgent.indexOf('chrome') != -1) {
    return 'chrome';
  } else if (_userAgent.indexOf('safari') != -1) {
    return 'safari';
  } else if (_userAgent.indexOf('firefox') != -1) {
    return 'firefox';
  } else if (_userAgent.indexOf('opera') != -1) {
    return 'opera';
  } else {
    return 'unknown';
  }
}

/**
 * setCookie
 */
export function setCookie(options) {
  const { name = '', expire_hour = 24 * 7, value = 1, same_site = 'lax', path = '/' } = options;

  const expire = new Date();
  const samesite = same_site === 'none' ? 'none; Secure' : same_site;

  expire.setTime(expire.getTime() + 1000 * 3600 * expire_hour);
  document.cookie = `${name}=${value}; expires=${expire.toUTCString()}; SameSite=${samesite}; path=${path};`;
}

/**
 * getCookie
 */
export function getCookie(name) {
  let result = null;

  const cookieName = name + '=';
  const allcookies = document.cookie;

  const position = allcookies.indexOf(cookieName);
  if (position != -1) {
    const startIndex = position + cookieName.length;

    let endIndex = allcookies.indexOf(';', startIndex);
    if (endIndex == -1) {
      endIndex = allcookies.length;
    }

    result = decodeURIComponent(allcookies.substring(startIndex, endIndex));
  }

  return result;
}

/**
 * deleteCookie
 */
export function deleteCookie(name) {
  const cookiName = encodeURIComponent(name);
  document.cookie = `${cookiName}=; max-age=0`;
}
