import React, { useState, useEffect } from 'react';
import useWebAnimations from '@wellyshen/use-web-animations';
// import styles from './Intro.module.scss'; -> styles/foundations/plugins/_intro.scss に移動

import LogoC from '@/img/foundations/logo/c.svg';
import LogoH from '@/img/foundations/logo/h.svg';
import LogoO from '@/img/foundations/logo/o.svg';
import LogoU from '@/img/foundations/logo/u.svg';
import LogoR from '@/img/foundations/logo/r.svg';
import LogoE from '@/img/foundations/logo/e.svg';
import LogoKatakana from '@/img/foundations/logo/katakana.svg';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { EASING } from '@/js/constants';

/**
 * @doc debugとして onSceneEnd をコメントする事で各シーンでストップ可能
 */
export const Intro = () => {
  const [scene, setScene] = useState(0); // シーン0 はCSS制御

  /**
   * シーン1 「チョキュア」フェードイン
   */
  const { ref: kanaRef, animate: kanaAnimate } = useWebAnimations<HTMLDivElement>({
    onFinish: () => {
      onSceneEnd();
    },
  });
  /**
   * シーン2 雫が垂れるモーション
   */
  const { ref: dropRef, animate: dropAnimate } = useWebAnimations<HTMLDivElement>({
    onFinish: () => {
      onSceneEnd();
    },
  });
  /**
   * シーン3 イントロ全体フェードアウト
   */
  const { ref: introRef, animate: introAnimate } = useWebAnimations<HTMLDivElement>({
    onFinish: () => {
      document.documentElement.classList.add('is-intro-end');
      document.querySelector('.js-intro')?.remove();
    },
  });

  useEffect(() => {
    if (scene === 1) {
      kanaAnimate({
        keyframes: [
          {
            opacity: 0,
            offset: 0,
          },
          {
            opacity: 1,
            offset: 1,
          },
        ],
        animationOptions: {
          duration: 200,
          easing: 'ease',
          fill: 'forwards',
        },
      });
    }

    if (scene === 2) {
      dropAnimate({
        keyframes: [
          {
            opacity: 1,
            transform: 'translateY(0%)',
            offset: 0,
          },
          {
            opacity: 1,
            transform: 'scaleX(0.76) translateY(-10px)',
            offset: 0.4,
          },
          {
            opacity: 1,
            transform: 'scaleX(0.76) translateY(-8px)',
            offset: 0.6,
          },
          {
            opacity: 0,
            transform: 'scaleX(1.3) translateY(100%)',
            offset: 1,
          },
        ],
        animationOptions: {
          duration: 1000,
          delay: 400,
          easing: EASING.easeInOutSine,
          fill: 'forwards',
        },
      });
    }

    if (scene === 3) {
      introAnimate({
        keyframes: {
          opacity: 0,
        },
        animationOptions: {
          duration: 800,
          easing: 'ease-out',
          fill: 'forwards',
        },
      });
    }
  }, [scene]);

  useEffect(() => {
    document.documentElement.classList.add('is-intro-play');
  }, []);

  const onSceneEnd = () => {
    setScene(scene + 1);
  };

  return (
    // <div className={styles.intro} ref={introRef}>
    //   <div className={styles.intro__logo}>
    //     <div className={styles.katakana} ref={kanaRef}>
    //       <LogoKatakana />
    //     </div>
    //     <div className={styles.chocure}>
    //       <div className={styles.chocureText}>
    //         <LogoC />
    //       </div>
    //       <div className={styles.chocureText}>
    //         <LogoH />
    //       </div>
    //       <div className={styles.chocureText} ref={dropRef}>
    //         <LogoO />
    //       </div>
    //       <div className={styles.chocureText}>
    //         <LogoC />
    //       </div>
    //       <div className={styles.chocureText}>
    //         <LogoU />
    //       </div>
    //       <div className={styles.chocureText}>
    //         <LogoR />
    //       </div>
    //       <div className={styles.chocureText} onAnimationEnd={onSceneEnd}>
    //         <LogoE />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="intro" ref={introRef}>
      <div className="intro__logo">
        <div className="katakana" ref={kanaRef}>
          <LogoKatakana />
        </div>
        <div className="chocure">
          <div className="chocureText">
            <LogoC />
          </div>
          <div className="chocureText">
            <LogoH />
          </div>
          <div className="chocureText" ref={dropRef}>
            <LogoO />
          </div>
          <div className="chocureText">
            <LogoC />
          </div>
          <div className="chocureText">
            <LogoU />
          </div>
          <div className="chocureText">
            <LogoR />
          </div>
          <div className="chocureText" onAnimationEnd={onSceneEnd}>
            <LogoE />
          </div>
        </div>
      </div>
    </div>
  );
};
