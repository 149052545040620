// @ts-ignore
import project from '@/js/project';

export default class Toc {
  selector;
  el: HTMLElement[] | [];

  constructor(selector: string) {
    this.selector = selector;
    this.el = [...document.querySelectorAll(selector)] as HTMLElement[] | [];

    if (this.el.length) {
      this.initialize();
    }
  }

  private initialize() {
    this.el.forEach((anchor) => {
      anchor.addEventListener('click', this.handleClick);
    });
  }

  private handleClick(event: Event) {
    event.stopPropagation();
    const target = event.target as HTMLAnchorElement;
    const selector = (target.getAttribute('href') || '').replace(/#(.*)/, '*[id="$1"]');
    project?.modules?.smoothScroll?.scroller.to(selector, { offset: -100 });
  }
}
