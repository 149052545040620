/**
 * queryToObject
 */
export function queryToObject(querystring, options) {
  const mergedOptions = {
    decode: false,
    ...options,
  };
  const pairs = querystring.replace(/^\?/, '').split('&');
  const searchParams = pairs.map((x) => x.split('=')).filter((x) => x[0]);

  return [...searchParams].reduce((acc, cur) => {
    const key = cur[0].replace(/\[(.*)?\]|%5B(.*)?%5D/, '');
    const value = mergedOptions.decode ? decodeURIComponent(cur[1]) : cur[1];

    return {
      ...acc,
      [key]: (/(\[(.*)?\]|%5B(.*)?%5D)$/.test(cur[0]) ? (!acc[key] ? [value] : acc[key].concat(value)) : value) || '',
    };
  }, {});
}
