import PubSub from 'vanilla-pubsub';
// import project from '../project'
import { wait } from '@/js/utils/event';

export default class Menu {
  constructor(selector) {
    this.el = document.querySelectorAll(selector);
    this.state = {
      active: false,
    };
    this.options = {
      openBeforeClass: 'is-menu-open-before',
      closeBeforeClass: 'is-menu-close-before',
      activeClass: 'is-menu-open',
    };
    if (this.el) {
      this._initialize();
    }
  }

  _bind() {
    PubSub.subscribe('App.changeViewport', this.close.bind(this));
  }

  _initialize() {
    this._bind();
    this.el.forEach((it) => {
      it.addEventListener('click', this.toggle.bind(this));
    });
  }

  async toggle() {
    if (this.state.active) {
      await this.close();
    } else {
      await this.open();
    }
  }

  async open() {
    document.body.classList.add(this.options.openBeforeClass);
    await wait(10);
    document.body.classList.remove(this.options.openBeforeClass);

    document.body.classList.add(this.options.activeClass);
    this.state.active = !this.state.active;
    PubSub.publish('disableScroll.disable');
  }

  async close() {
    document.body.classList.add(this.options.closeBeforeClass);
    await wait(600);
    document.body.classList.remove(this.options.closeBeforeClass);

    document.body.classList.remove(this.options.activeClass);
    this.state.active = !this.state.active;
    PubSub.publish('disableScroll.release');
  }
}
