import PubSub from 'vanilla-pubsub';
import project from '../project';

export default class Viewport {
  constructor() {
    this.root = document.documentElement;

    this.bind();
    this.initialize();

    if (!this.root.style['--vh']) {
      this.onResize();
    }
  }

  bind() {
    this.onResize = this.onResize.bind(this);
    PubSub.subscribe('App.resize', this.onResize);
  }

  initialize() {
    const { width, height, scrollBarWidth } = project.state;
    const vw = width;
    const vh = height;
    const outerVW = window.outerHeight; // SP時、OS UI込のサイズとして
    const outerVH = window.outerWidth; // SP時、OS UI込のサイズとして

    // 初期値固定
    this.root.style.setProperty('--svw', `${vw}px`);
    this.root.style.setProperty('--svh', `${vh}px`);
    this.root.style.setProperty('--vw', `${outerVW}px`);
    this.root.style.setProperty('--vh', `${outerVH}px`);
    this.root.style.setProperty('--bar-w', `${scrollBarWidth}px`);

    this.updateRatio();
  }

  updateRatio() {
    const { width, height } = project.state;

    if (height > width) {
      this.root.classList.remove('landscape');
      this.root.classList.add('portrait');
    } else {
      this.root.classList.remove('portrait');
      this.root.classList.add('landscape');
    }

    if (height > width / 2) {
      this.root.classList.remove('landscape-half');
      this.root.classList.add('portrait-half');
    } else {
      this.root.classList.remove('portrait-half');
      this.root.classList.add('landscape-half');
    }
  }

  // Fallback CSS 4
  // @doc https://drafts.csswg.org/css-values-4/#large-viewport-percentage-units
  onResize(state) {
    const { width, height, scrollBarWidth } = state || project.state;
    const vw = width / 100;
    const vh = height / 100;

    this.root.style.setProperty('--dvw', `${width}px`);
    this.root.style.setProperty('--dvh', `${height}px`);
    this.root.style.setProperty('--bar-w', `${scrollBarWidth}px`);

    if (vh > vw) {
      this.root.style.setProperty('--vmax', `${vh}px`);
      this.root.style.setProperty('--vmin', `${vw}px`);
    } else {
      this.root.style.setProperty('--vmax', `${vw}px`);
      this.root.style.setProperty('--vmin', `${vh}px`);
    }

    this.updateRatio();
  }
}
